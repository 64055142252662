<template>
<div class="stats">
	<div class="buttons">
		<button @click="importerXls">
			<fa icon="folder-open"/>
			Charger
		</button>
		<button @click="uploadXls" v-if="loaded.length>0">
			<fa icon="upload"/>
			Uploader {{ loaded.length }} elements
		</button>
		<input type="file" ref="fichier_produits" style="display: none;"
			@change="loadFile" accept=".csv">
	</div>
	<div class="table" :class="{'empty': titles.length==0}">
		<table>
			<thead>
				<tr>
					<td v-for="title in titles">
						<select @change="e => transform(title, e.target.value)">
							<option :value="null"></option>
							<option
								v-for="affect in Object.keys(affectations)"
								:value="affect">
								{{ affect.replaceAll("_", " ").toUpperCase() }}
							</option>
						</select>
					</td>
				</tr>
				<tr>
					<td v-for="title in titles">
						{{ title }}
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in loaded">
					<td v-for="title in titles">
						{{ item[title] }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
</template>
<script>
export default{
	data(){
		return {
			loaded: [],
			titles: [],
			affectations: {
				"nom": null,
				"prix_vente_unitaire": null,
				"quantite": null,
				"unite": null,
				"prix_achat_total": null
			},
			to_upload: []
		}
	},
	methods:{
		importerXls(){
			let inp_file = this.$refs["fichier_produits"]
			inp_file.click()
		},
		loadFile(event){
			if (event.target.files && event.target.files[0]) {
				let reader = new FileReader();
				reader.readAsBinaryString(event.target.files[0]);
				reader.onload = e => {
					const file = e.target.result;
					const lines = file.split(/\r\n|\n/);
					let array_line = []
					let obj = null
					this.titles = lines[0].split(";")
					lines.splice(0, 1)
					this.loaded = []
					for(let line of lines){
						array_line = line.split(";");
						if(array_line.length<3) continue;
						obj = {}
						for (var i = 0; i < this.titles.length; i++) {
							try {
								obj[this.titles[i]] = array_line[i].trim()
							} catch(e) {}
						}
						this.loaded.push(obj)
					};
				};
				reader.onerror = (event) => {
					alert(event.target.error.name);
				};
			}
		},
		transform(title, value){
			if(this.affectations.hasOwnProperty(value)){
				this.affectations[value] = title
			}
		},
		uploadXls(){
			for(let key of Object.keys(this.affectations)){
				if(this.affectations[key] == null){
					alert(`Vous n'avez pas choisi une colonne pour <<${key}>>`)
					return
				}
			}
			let user = this.active_user
			let confirm = prompt(`\
Tout les données actuelles du kiosk seront completement écrasé à savoir
- les VENTES faites
- les STOCKS enregistré
- les PRODUITS crées
Veuillez confirmer que vous en êtes conscient en tapant
<< ${user.username} >>`)
			console.log(confirm, user.username)
			if(!confirm || confirm != user.username){
				return
			}
			let obj, value
			for(let item of this.loaded){
				obj = {}
				for(let line of Object.entries(this.affectations)){
					value = item[line[1]]
					if(value.toUpperCase() == value.toLowerCase()){
						value = value.replaceAll(" ", "")
					}
					obj[line[0]] = value
				}
				this.to_upload.push(obj)
			}
			let kiosk = this.getActiveKiosk().id
			axios.post(this.url+`/kiosk/${kiosk}/create_content/`, this.to_upload, this.headers)
			.then((response) => {
				this.$store.state.alert = {
					type:"success", message:response.data
				}
			}).catch((error) => {
				this.errorOrRefresh(error, this.uploadXls)
			});
		},
	}
};
</script>
<style scoped>
.buttons{
	display: flex;
	justify-content: space-between;
}
.table{
	min-height: calc(100vh - 105px);
}
.empty, thead{
	background-color: #eee;
	font-weight: 700;
}
select{
	width: 100%;
}
</style>